import { Card, Col, Row } from 'antd';
import * as React from 'react';
import { useStoreActions, useStoreState } from 'store';

interface IDashboardProps {}

const DashboardRoute: React.FunctionComponent<IDashboardProps> = (props) => {
  const { resturant, user } = useStoreState((store) => store.Auth);
  const { logout } = useStoreActions((state) => state.Auth);

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
      <Col span={10}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Grafik">
          <div style={{ height: 200 }} />
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardRoute;
