import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import * as React from 'react';

interface ITagsProps {
  tags: string[] | React.ReactNodeArray | React.ReactNode[] | undefined;
  max?: number;
  tagProps?: TagProps;
}

const Tags: React.FunctionComponent<ITagsProps> = ({ tags, max = 3, tagProps, ...props }) => {
  const $tags = (tags?.length && tags.slice(0, max)) || [];

  const $others = tags && tags.length - $tags.length;

  return (
    <div>
      {$tags.map((t, i) => (
        <Tag key={i} style={{ marginBottom: 5 }} {...tagProps}>
          {t}
        </Tag>
      ))}
      {$others && $others > 0 ? <Tag>and {$others} more</Tag> : null}
    </div>
  );
};

export default Tags;
