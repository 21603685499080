import React, { ReactNode } from 'react';
import { Modal as AntdModal, Spin } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { SpinProps } from 'antd/lib/spin';
import './form-modal.less';

export interface IFormModal extends ModalProps {
  children?: ReactNode;
  onSubmit: SubmitHandler<Record<string, any>>;
  loading?: boolean;
  spinProps?: SpinProps;
}
// export interface ITextAreaProps extends TextAreaProps {}

const ComponentErrorMessage = 'You should wrap <FormModal> component with <FormProvider>, there is no form context to implement!';

const defaultProps: IFormModal = {
  okButtonProps: {
    htmlType: 'submit',
  },
  okText: 'Save',
  onSubmit: async () => {},
  destroyOnClose: true,
};

const FormModal = (props: IFormModal) => {
  const containerRef = React.useRef(null);

  const formContext = useFormContext();

  if (!formContext) {
    console.error(`${props.title || null} ${ComponentErrorMessage}`);
    throw ComponentErrorMessage;
  }

  const { handleSubmit, formState } = formContext;

  defaultProps.okButtonProps = {
    ...defaultProps.okButtonProps,
    loading: props.confirmLoading || formState.isSubmitting ? { delay: 10 } : false,
  };

  return (
    <form ref={containerRef} onSubmit={handleSubmit(props.onSubmit)}>
      <AntdModal getContainer={containerRef.current} {...defaultProps} {...props}>
        <Spin spinning={false} {...props.spinProps}>
          {props.children}
        </Spin>
      </AntdModal>
    </form>
  );
};

export default FormModal;
