import { Avatar, Button, Col, Divider, List, Row, Space, Typography, Modal, message } from 'antd';
import { FadeInUp } from 'components/animate/fade-in-up';
import Tags from 'components/tags';
import { ISearchableList } from 'models/components/list';
import { Product } from 'models/product';
import * as React from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { NavLink } from 'react-router-dom';
import { DeleteProduct, GetProducts } from 'services/products';
import { useStoreState } from 'store';
import ProductOptionList from './../option-item/list';

interface IProductsListProps extends ISearchableList<Product[]> {}

const ProductsList: React.FunctionComponent<IProductsListProps> = (props) => {
  const { orderProductGroup } = useStoreState((store) => store.Globals);

  const { data, isLoading, isFetching } = useQuery(['products', { orderProductGroup }], GetProducts, { refetchInterval: 20000 });

  const [expand, setexpand] = React.useState<number | null>(null);

  const dataSource = data ? props.filter(data) : data;

  return isLoading ? (
    <div>Loading..</div>
  ) : (
    <FadeInUp key={orderProductGroup}>
      <>
        <Typography.Text type="secondary">
          <div style={{ marginBottom: 20 }}>{dataSource ? `Listed ${dataSource.length} products` : null}</div>
        </Typography.Text>
        <List dataSource={dataSource} className="product-list" renderItem={(item, index) => <ProductListItem product={item} index={index} setExpand={setexpand} expand={expand} />} />
      </>
    </FadeInUp>
  );
};

export default ProductsList;

interface IListExpandProps<T> {
  item: T;
  expand: boolean;
}

const ListExpand: React.FunctionComponent<IListExpandProps<Product>> = ({ item, expand, ...props }) => {
  React.useEffect(() => {
    if (expand) {
    }
  }, [expand]);

  return <ProductOptionList product={item} />;
};

interface IProductListproduct {
  product: Product;
  setExpand: (index: number | null) => void;
  expand: number | null;
  index: number;
}

const ProductListItem: React.FunctionComponent<IProductListproduct> = React.memo(({ product, setExpand, expand, index, ...props }) => {
  const cache = useQueryCache();

  const showDeleteConfirm = (product: Product) => {
    if (product && product.id) {
      Modal.confirm({
        title: (
          <>
            <Typography.Text type="secondary">Are you sure to want delete </Typography.Text>
            {product.name}
          </>
        ),
        okType: 'danger',
        centered: true,
        okText: 'Yes',
        async onOk() {
          try {
            if (product.id) await DeleteProduct(product.id);
            message.success(`${product.name} deleted`);
            cache.invalidateQueries('products');
          } catch (e) {
            message.error(e);
          }
        },
        onCancel() {},
      });
    }
  };

  return (
    <List.Item>
      <Col span={24} onClick={() => (expand !== index ? setExpand(index) : setExpand(null))}>
        <Row align="middle" gutter={16}>
          <Col xs={24} md={16} lg={8}>
            <List.Item.Meta
              title={product.name}
              description={
                <Typography.Text type="secondary" style={{ maxWidth: '90%' }} ellipsis={true}>
                  {product.description}
                </Typography.Text>
              }
              avatar={<Avatar size={46} src={product.imageUrl} />}
            ></List.Item.Meta>
          </Col>
          <Col flex={'0 0 160px'}>
            <Space direction="vertical" size={2}>
              <Typography.Text type="success">£{product.price} </Typography.Text>
              <div>
                <Typography.Text type="danger" style={{ fontSize: 12 }}>
                  £{product.discountedPrice}
                  <small> (discount)</small>
                </Typography.Text>
              </div>
            </Space>
          </Col>
          <Col flex="1 1 auto">{product.categories && <Tags tagProps={{ color: '#7d89b9' }} tags={product.categories?.map((i) => i.name)}></Tags>}</Col>
          <Col onClick={(e) => e.stopPropagation()}>
            <Space>
              <Button onClick={() => showDeleteConfirm(product)} type="text">
                Delete
              </Button>
              <NavLink to={`/products/${product.id}`}>Edit</NavLink>
            </Space>
          </Col>
        </Row>
      </Col>

      {expand === index && (
        <>
          <Col span={24}>
            <Divider />
            <ListExpand expand={expand === index} item={product} />
          </Col>
        </>
      )}
    </List.Item>
  );
});
