import { Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './form-item.less';

import { FormItemProps } from 'antd/lib/form/FormItem';
import { DeepMap, FieldError } from 'react-hook-form';

interface IFormItemProps extends FormItemProps {
  label?: string;
  error?: FieldError;
  required?: boolean;
  direction?: 'vertical' | 'horizontal';
  marginBottom?: number;
}

export default function FormItem({ label = '', direction = 'vertical', marginBottom, required = false, ...props }: IFormItemProps) {
  const [hasError, setHasError] = useState(false);
  const ref = useRef(null);

  const { error } = props;

  useEffect(() => {
    if (error && error.type) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);

  let labelCol = direction === 'vertical' ? { lg: 24, xs: 24, md: 24 } : {};

  const style = { ...props.style, marginBottom };

  return (
    <div ref={ref}>
      <Form.Item className="form-item-component" style={style} htmlFor={props.htmlFor} labelCol={labelCol} validateStatus={hasError ? 'error' : ''} required={required} label={label} hasFeedback={true} {...props} />
    </div>
  );
}
