import { SetAuthCatcher } from 'API';
import { AxiosRequestConfig } from 'axios';
import * as React from 'react';
import { useQueryCache } from 'react-query';
import { HashRouter as Router } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'store';
import { FlattenPrivateRoutes, FlattenPublicRoutes } from './routes';

const PublicContainer = React.lazy(() => import('containers/public-layout'));
const PrivateContainer = React.lazy(() => import('containers/private-layout'));

interface IRoutesProps {}

const Routes: React.FunctionComponent<IRoutesProps> = () => {
  const cache = useQueryCache();

  const { valid, token, resturant, user } = useStoreState((state) => state.Auth);
  const { orderProductGroup } = useStoreState((state) => state.Globals);
  const { setTokenHeaderSet } = useStoreActions((store) => store.Auth);

  async function AuthConfiguration(req: AxiosRequestConfig) {
    return new Promise(async (resolve, reject) => {
      if (token && resturant && user) {
        req.headers['Authorization'] = `Bearer ${token}`;

        if (req.method === 'get' || req.method === 'GET') {
          req.params = {
            ...req.params,
            restaurantId: resturant?.id,
            merchantCompanyId: user?.merchantCompanyId,
            orderProductGroup: req.params?.orderProductGroup ? req.params.orderProductGroup : orderProductGroup,
          };
        }

        req.params = {
          ...req.params,
          restaurantId: resturant?.id,
          merchantCompanyId: user?.merchantCompanyId,
        };

        if (req.method === 'PUT' || req.method === 'put' || req.method === 'post' || req.method === 'POST') {
          req.data = req.data;
        }

        if (req.method === 'delete' || req.method === 'DELETE') {
          req.data = {};

          req.params = {
            ...req.params,
            restaurantId: resturant?.id,
            merchantCompanyId: user?.merchantCompanyId,
          };
        }

        resolve(true);
      } else {
        delete req.headers['Authorization'];
        resolve();
      }
    });
  }

  React.useEffect(() => {
    const StartAPIConfiguration = async () => {
      try {
        await SetAuthCatcher(AuthConfiguration);
        setTokenHeaderSet(true);
        // await cache.refetchQueries();
        // cache.clear({notify});
      } catch (error) {
        console.error('Error on set api configuration -> ', error);
        setTokenHeaderSet(false);
      }
    };

    StartAPIConfiguration();
  }, [token, resturant, user, orderProductGroup]);

  return (
    <React.Suspense fallback="">
      <Router>{token ? valid ? <PrivateContainer routes={FlattenPrivateRoutes} /> : <div>Loading..</div> : <PublicContainer routes={FlattenPublicRoutes} />}</Router>
    </React.Suspense>
  );
};

export default Routes;
