import { Avatar, Button, Card, Col, List, message, Modal, Row, Space, Typography } from 'antd';
import { FadeInUp } from 'components/animate/fade-in-up';
import { Category } from 'models/category';
import { ISearchableList } from 'models/components/list';
import * as React from 'react';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { DeleteCategory, GetCategories, GetCategoriesWithProduct } from 'services/categories';
import { useStoreState } from 'store';

interface ICategoriesListProps extends ISearchableList<Category[]> {}

const CategoriesList: React.FunctionComponent<ICategoriesListProps> = ({ filter, ...props }) => {
  const { orderProductGroup } = useStoreState((store) => store.Globals);
  const { data, isLoading, isFetching } = useQuery(['categories', { orderProductGroup }], GetCategories);

  const $dataSource = data ? filter(data) : data;

  return isLoading ? (
    <div>Yükleniyor..</div>
  ) : (
    <FadeInUp key={orderProductGroup}>
      <>
        <Typography.Text type="secondary">
          <div style={{ marginBottom: 20 }}>{$dataSource ? `Listed ${$dataSource.length} categories` : null}</div>
        </Typography.Text>
        <List loading={isFetching} dataSource={$dataSource} renderItem={(item) => <CategoryListItem category={item} />} />
      </>
    </FadeInUp>
  );
};

export default CategoriesList;

interface ICategoryListItem {
  category: Category;
}

const CategoryListItem: React.FunctionComponent<ICategoryListItem> = React.memo(({ category, ...rest }) => {
  const showDeleteConfirm = (category: Category) => {
    if (category && category.id) {
      Modal.confirm({
        title: (
          <>
            <Typography.Text type="secondary">Do you want to delete </Typography.Text>
            {category.name}
          </>
        ),
        okType: 'danger',
        centered: true,
        okText: 'Yes',
        async onOk() {
          try {
            if (category.id) await DeleteCategory({ categoryId: category.id });
            message.success(`${category.name} deleted`);
          } catch (e) {
            message.error(e);
          }
        },
        onCancel() {},
      });
    }
  };

  return (
    <List.Item
      extra={
        <Space>
          <Button onClick={() => showDeleteConfirm(category)} type="text">
            Delete
          </Button>
          <NavLink aria-disabled={true} to={`/categories/${category.id}`}>
            <Button disabled={true} type="link">
              Edit
            </Button>
          </NavLink>
        </Space>
      }
      title={category.name}
    >
      <Row style={{ flexGrow: 1 }}>
        <Col span={24}>
          <List.Item.Meta title={category.name} description={category.description} avatar={<Avatar size={46} src={category.imageUrl} />}></List.Item.Meta>
        </Col>
      </Row>
    </List.Item>
  );
});
