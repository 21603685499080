import { motion, MotionProps } from 'framer-motion';
import * as React from 'react';

export interface IFadeInUpAnimateProps extends MotionProps {
  children: JSX.Element | React.FC<{}> | Element;
}
const FadeInUpAnimation: MotionProps = {
  exit: { y: 80, opacity: -2 },
  transition: { damping: 0, duration: 0.2, delay: 0.2 },
  initial: { y: 80, opacity: -2 },
  animate: { y: 0, opacity: 1 },
};

export function FadeInUp(props: IFadeInUpAnimateProps) {
  return <motion.div {...FadeInUpAnimation} {...props} />;
}
