import React from 'react';
import { Select as AntdSelect } from 'antd';
import { SelectProps, SelectValue, OptionProps } from 'antd/lib/select';

export interface ISelectProps extends SelectProps<SelectValue> {}

type Ref = React.RefObject<React.ReactElement>;

const Select = React.forwardRef<Ref, ISelectProps>((props, ref) => {
  return <AntdSelect {...props} />;
});

const Option = AntdSelect.Option;
const OptGroup = AntdSelect.OptGroup;

export { Option, OptGroup };
export default Select;
