import { action, thunk, Action, computed, persist, Thunk, Computed } from 'easy-peasy';
import StoreStatus from 'types/storeStatus';
import { Login } from 'services/authenticate';
import { User } from 'models/user';
import { ILogin } from 'models/services/authenticate';
import { Resturant } from 'models/restaurant';

interface IAuthStoreState {
  token: string | undefined;
  user: User | undefined;
  tokenHeaderSet: any;
  request: StoreStatus;
  error: string | null;
}

export interface IAuthStoreModel extends IAuthStoreState {
  setTokenHeaderSet: Action<IAuthStoreState, boolean>;
  setRequest: Action<IAuthStoreState, StoreStatus>;
  setError: Action<IAuthStoreState, string>;
  login: Thunk<IAuthStoreModel, ILogin, string>;
  loginSuccess: Action<IAuthStoreState, string>;
  logout: Thunk<IAuthStoreModel, string>;
  logoutSuccess: Action<IAuthStoreState>;
  setUser: Action<IAuthStoreState, User>;
  setToken: Action<IAuthStoreState, string>;
  valid: Computed<IAuthStoreState>;
  resturant: Computed<IAuthStoreState, Resturant | undefined>;
}

const Auth: IAuthStoreModel = persist(
  {
    token: undefined,
    user: undefined,
    tokenHeaderSet: false,
    resturant: computed((state) => state.user?.restaurants[0]),
    valid: computed((state) => (!!state.token && !!state.user?.id && state.tokenHeaderSet) || false),
    request: StoreStatus.IDLE,
    error: null,
    setTokenHeaderSet: action((state, payload) => {
      state.tokenHeaderSet = payload;
    }),
    setRequest: action((state, payload) => {
      state.request = payload;
    }),
    setError: action((state, payload) => {
      state.error = payload;
      throw payload;
    }),

    /**
     * Login actions
     */
    login: thunk(async (actions, payload, helpers) => {
      actions.setRequest(StoreStatus.PENDING);
      try {
        const user = await Login(payload);

        const { token } = user.token;

        actions.loginSuccess(token);
        actions.setUser(user);
        actions.setRequest(StoreStatus.SUCCESS);
        return token;
      } catch (error) {
        console.error('Error while login -> ', error);
        actions.setRequest(StoreStatus.ERROR);
        actions.setError(error);
      }
      actions.setRequest(StoreStatus.IDLE);
    }),
    loginSuccess: action((state, payload) => {
      state.token = payload;
    }),

    /**
     * Logout actions
     */
    logout: thunk(async (actions, payload) => {
      actions.logoutSuccess();
    }),
    logoutSuccess: action((state, payload) => {
      state.token = undefined;
      state.user = undefined;
      state.tokenHeaderSet = false;
    }),

    setUser: action((state, payload) => {
      state.user = payload;
    }),
    setToken: action((state, payload) => {
      state.token = payload;
    }),
  },
  { blacklist: ['tokenHeaderSet'], storage: 'localStorage' },
);

export default Auth;
