import { motion, MotionProps } from 'framer-motion';
import * as React from 'react';

export interface IFadeInAnimateProps extends MotionProps {
  children: React.Component | JSX.Element;
}

const FadeInAnimation: MotionProps = {
  exit: { opacity: 0 },
  transition: { damping: 0, duration: 0.23, delay: 0 },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

export function FadeIn(props: IFadeInAnimateProps) {
  return <motion.div {...FadeInAnimation} {...props} />;
}
