import { message } from 'antd';
import { Product } from 'models/product';
import * as React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useQuery, useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { GetProduct, UpdateProduct } from 'services/products';
import { sleep } from 'utils';
import { ProductForm } from '../form';

export interface IUpdateProductProps {}

export function UpdateProductForm(props: IUpdateProductProps) {
  const params = useParams<{ productId: string | undefined }>();

  const history = useHistory();

  const [visible, setvisible] = React.useState(false);
  const [closing, setClosing] = React.useState(false);

  const productId = params.productId ? params.productId : undefined;

  const cache = useQueryCache();

  const toggleProduct = React.useCallback(async () => {
    if (!setvisible) return;
    setvisible(false);
    setClosing(true);
    await sleep(150);
    setClosing(false);
    history.push('/products');
  }, []);

  const { data, isLoading } = useQuery(['product', { productId: productId }], GetProduct, { enabled: params.productId });

  React.useLayoutEffect(() => {
    if (!visible && productId && params.productId && !closing) {
      setvisible(true);
    }
  }, [visible, productId, params.productId]);

  const onProductSubmit: SubmitHandler<Product> = async (product) => {
    console.log('Update Product form -> ', product);

    product.id = productId;

    try {
      await sleep(120);
      await UpdateProduct(product);
      cache.invalidateQueries('products');
      cache.invalidateQueries(['product', { productId: productId }]);
      toggleProduct();
    } catch (error) {
      console.error('Error while updating product -> ', error);
      message.error(error);
    }
  };

  return <ProductForm title="Edit Product" spinProps={{ spinning: isLoading }} product={data} onSubmit={onProductSubmit} onCancel={toggleProduct} visible={visible} />;
}
