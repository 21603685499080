import * as React from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import './button.less';

interface IButtonProps extends ButtonProps {}

const Button: React.FunctionComponent<IButtonProps> = (props) => {
  return <AntdButton {...props} loading={props.loading ? { delay: 10 } : false} />;
};

export default Button;
