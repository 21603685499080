import { message } from 'antd';
import { Product } from 'models/product';
import * as React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useQuery, useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { GetCategory } from 'services/categories';
import { UpdateProduct } from 'services/products';
import { sleep } from 'utils';
import { CategoryForm } from '../form';

export interface IUpdateCategoryProps {}

export function UpdateCategoryForm(props: IUpdateCategoryProps) {
  const params = useParams<{ categoryId: string | undefined }>();

  const history = useHistory();

  const [visible, setvisible] = React.useState(false);
  const [closing, setClosing] = React.useState(false);

  const categoryId = params.categoryId ? params.categoryId : undefined;

  const cache = useQueryCache();

  const toggleCategory = React.useCallback(async () => {
    setvisible(false);
    setClosing(true);
    await sleep(150);
    setClosing(false);
    history.push('/categories');
  }, []);

  const { data, isLoading } = useQuery(['category', categoryId], GetCategory, { enabled: params.categoryId });

  React.useEffect(() => {
    if (!visible && categoryId && !closing) {
      setvisible(true);
    }
  }, [visible, categoryId, params.categoryId]);

  const onCategorySubmit: SubmitHandler<Product> = async (product) => {
    console.log('Update Product form -> ', product);

    product.id = categoryId;

    try {
      await sleep(120);
      // await UpdateProduct(product);
      cache.invalidateQueries('categories');
      cache.invalidateQueries(['category', categoryId]);
      toggleCategory();
    } catch (error) {
      console.error('Error while updating product -> ', error);
      message.error(error);
    }
  };

  return <CategoryForm title="Edit Product" spinProps={{ spinning: isLoading }} category={data} onSubmit={onCategorySubmit} onCancel={toggleCategory} visible={visible} />;
}
