import { IRoutes } from 'types/route';
import LoginRoute from './login';
import flattenRoutes from 'utils/flattenRoutes';
import DashboardRoute from './dashboard';
import MenuPage from './menu';
import CategoriesPage from './categories';
import ProductsPage from './products';

const PublicRoutes: IRoutes = [
  {
    path: '/',
    title: 'Login',
    component: LoginRoute,
    exact: true,
  },
  {
    path: '/login',
    title: 'Login',
    component: LoginRoute,
    exact: true,
  },
];

const PrivateRoutes: IRoutes = [
  {
    path: '/',
    title: 'Dashboard',
    component: DashboardRoute,
    exact: true,
  },
  {
    path: '/menu',
    title: 'Test Private',
    component: MenuPage,
    exact: true,
  },
  {
    path: '/categories',
    title: 'Categories',
    component: CategoriesPage,
    exact: false,
  },
  {
    path: '/products',
    title: 'Products',
    component: ProductsPage,
    exact: false,
  },
];

const FlattenPublicRoutes: IRoutes = flattenRoutes(PublicRoutes);
const FlattenPrivateRoutes: IRoutes = flattenRoutes(PrivateRoutes);

export { PublicRoutes, FlattenPublicRoutes, FlattenPrivateRoutes, PrivateRoutes };
