import { message } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { Category } from 'models/category';
import * as React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQueryCache } from 'react-query';
import { CreateCategory } from 'services/categories';
import { useStoreState } from 'store';
import { CategoryForm } from '../form';

export interface ICreateCategoryViewProps extends ModalProps {
  onSuccess?: () => void;
}

export function CreateCategoryView(props: ICreateCategoryViewProps) {
  const { orderProductGroup } = useStoreState((store) => store.Globals);

  const cache = useQueryCache();

  const [mutateCreateCategory] = useMutation(CreateCategory, {
    onSuccess: () => cache.invalidateQueries(['categories', { orderProductGroup }]),
  });

  const HandleCreateCategory: SubmitHandler<Category> = async (category) => {
    try {
      await mutateCreateCategory(category);
      props.onSuccess && props.onSuccess();
      message.success('New category added');
    } catch (error) {
      console.error('Error while creating a product -> ', error);
      message.error(error);
    }
  };

  return <CategoryForm onSubmit={HandleCreateCategory} {...props} />;
}
