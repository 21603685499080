import { API_V1, API_V2 } from 'API';
import { OrderProductGroup } from 'enums/product';
import { Category } from 'models/category';

interface GetCategoryParams {
  orderProductGroup: OrderProductGroup;
}

export async function GetCategoriesWithProduct(key: string, params: GetCategoryParams) {
  return await API_V2.get<Category[]>('category/list-products', { params });
}

export async function GetCategories(key: string, params: GetCategoryParams) {
  return await API_V1.get<Category[]>('category/list', { params });
}

export async function CreateCategory(category: Category) {
  return await API_V1.put<Category>('category', category);
}

export async function GetCategory(key: string, categoryId: string) {
  return await API_V1.get<Category>('category', { params: { categoryId } });
}

export async function DeleteCategory(params: { categoryId: string; merchantCompanyId?: string }) {
  return await API_V1.delete<Category>('category', { params });
}
