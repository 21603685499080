import { Button, Col, Divider, Input, Row } from 'antd';
import * as React from 'react';
import Icon from 'react-icons-kit';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { Route } from 'react-router-dom';
import { CreateProductView } from './create';
import ProductsList from './list';
import { UpdateProductForm } from './update';

export interface IProductsPageProps {}

export default function ProductsPage(props: IProductsPageProps) {
  const [searchValue, setsearchValue] = React.useState<string>('');
  const [productFormVisibility, setproductFormVisibility] = React.useState<boolean>(false);
  const searchInput = React.useRef(null);

  const toggleCreateProductFormVisibility = React.useCallback(() => {
    setproductFormVisibility((state) => !state);
  }, []);

  return (
    <>
      <Row gutter={12}>
        <Col xs={24} lg={18} xl={12} xxl={10}>
          <Row gutter={[10, 10]}>
            <Col flex={'1 1 60%'}>
              <Input.Search size="large" allowClear ref={searchInput} placeholder="Type product name and press enter for filter..." onChange={(e) => (e.currentTarget.value.length < 3 ? setsearchValue('') : setsearchValue(e.currentTarget.value.trim()))} />
            </Col>
            <Col flex="1 1 10%">
              <CreateProductView onSuccess={toggleCreateProductFormVisibility} onCancel={toggleCreateProductFormVisibility} visible={productFormVisibility} title="Create new product" />
              <Button onClick={toggleCreateProductFormVisibility} block type="link" icon={<Icon icon={ic_add} size={18} />} size="large">
                Add new product
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24}>
          <ProductsList filter={(data) => data.filter((d) => d.name?.toLocaleLowerCase().includes(searchValue) || d.description?.toLocaleLowerCase().includes(searchValue))} />
        </Col>
      </Row>
      <Route path="/products/:productId" render={(props) => <UpdateProductForm {...props} />} />
    </>
  );
}
