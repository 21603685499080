import { ModalProps } from 'antd/lib/modal';
import { Product, ProductOption } from 'models/product';
import * as React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { AddProductOption } from 'services/products';
import ProductOptionForm from '../form';

export interface ICreateProductOptionItemProps extends ModalProps {
  product: Product;
  onSuccess?: () => void;
}

export function CreateProductOptionItem({ product, onSuccess, ...props }: ICreateProductOptionItemProps) {
  const cache = useQueryCache();

  const HandleCreateProductOption: SubmitHandler<ProductOption> = async (productOption) => {
    console.log('Product option form submitted.. -> ', productOption);
    try {
      await AddProductOption(product, [productOption]);
      cache.invalidateQueries('product');
      onSuccess && onSuccess();
    } catch (error) {
      console.error('Error while creating a product -> ', error);
    }
  };

  return <ProductOptionForm onSubmit={HandleCreateProductOption} {...props} />;
}
