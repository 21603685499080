import { clear } from 'console';
import * as React from 'react';
import { sleep } from 'utils';
import './error-item.less';

interface IErrorItemProps {
  error?: string | number | object | undefined | null;
}

const ErrorItem: React.FunctionComponent<IErrorItemProps> = ({ error, ...props }) => {
  const [highlightError, setHighlightError] = React.useState<boolean | string | null | undefined>(null);

  const [$error, set$Error] = React.useState(false);

  const hasErr = (error !== null && error !== '' && error !== undefined) || (error && typeof error === 'object' && Object.keys(error).length > 0);

  React.useEffect(() => {
    if (hasErr) {
      setHighlightError(true);
      if (!$error) {
        set$Error(true);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (!highlightError) return;

    const showErrorInterval = setInterval(() => {
      console.log('Highlight', highlightError);
      if (highlightError) {
        setHighlightError(false);
      }
    }, 3000);

    return () => {
      clearInterval(showErrorInterval);
    };
  }, [highlightError]);

  let className = `kj-error-item`;

  if (highlightError) {
    className += ' highlight';
  }

  if ($error) {
    className += ' has-error';
  }

  return <div className={className}>{props.children}</div>;
};

export default ErrorItem;
