import { yupResolver } from '@hookform/resolvers';
import { Col, Input, Row } from 'antd';
import ErrorItem from 'components/error-item';
import FormItem from 'components/form-item';
import FormModal, { IFormModal } from 'components/form-modal/form-modal';
import { Category } from 'models/category';
import * as React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQuery, useQueryCache } from 'react-query';
import { GetCategories } from 'services/categories';
import { useStoreState } from 'store';
import * as yup from 'yup';

export interface ICategoryFormProps extends IFormModal {
  category?: Category;
}

const CategoryFormValidation = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().notRequired().nullable(),
  imageUrl: yup.string().url().nullable().notRequired(),
});

export function CategoryForm(props: ICategoryFormProps) {
  const { category } = props;

  const { orderProductGroup } = useStoreState((store) => store.Globals);

  const { data: categories, isLoading } = useQuery(['category'], GetCategories, { enabled: !category });

  const CategoryFormMethods = useForm<Category>({ defaultValues: category, resolver: yupResolver(CategoryFormValidation) });

  React.useEffect(() => {
    if (category) {
      CategoryFormMethods.reset(category);
    }

    return () => {};
  }, [category]);

  const { control, errors, register } = CategoryFormMethods;

  return (
    <FormProvider {...CategoryFormMethods}>
      <FormModal {...props}>
        <input type="hidden" value={orderProductGroup} name="orderProductGroup" defaultValue={orderProductGroup} ref={register} />
        <Row gutter={10}>
          <Col span={24}>
            <ErrorItem error={errors.name}>
              <FormItem error={errors.name}>
                <Controller defaultValue={category?.name || null} as={<Input placeholder="Category Name" />} control={control} name="name" />
              </FormItem>
            </ErrorItem>
          </Col>
          <Col span={24}>
            <ErrorItem error={errors.description}>
              <FormItem error={errors.description}>
                <Controller defaultValue={category?.description || null} as={<Input.TextArea rows={3} placeholder="Category description" />} control={control} name="description" />
              </FormItem>
            </ErrorItem>
          </Col>
          <Col span={24}>
            <ErrorItem error={errors.imageUrl}>
              <FormItem error={errors.imageUrl}>
                <Controller defaultValue={category?.imageUrl || null} as={<Input placeholder="Photo URL (ex: http://restaurant.mobildeli.co.uk/expamle.png)" />} control={control} name="imageUrl" />
              </FormItem>
            </ErrorItem>
          </Col>
        </Row>
      </FormModal>
    </FormProvider>
  );
}
