import { message } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { Product } from 'models/product';
import * as React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQueryCache } from 'react-query';
import { CreateProduct } from 'services/products';
import { useStoreState } from 'store';
import { ProductForm } from '../form';

export interface ICreateProductViewProps extends ModalProps {
  onSuccess?: () => void;
}

export function CreateProductView(props: ICreateProductViewProps) {
  const { orderProductGroup } = useStoreState((store) => store.Globals);

  const cache = useQueryCache();

  const [mutateCreateProduct] = useMutation(CreateProduct, {
    onSuccess: () => cache.invalidateQueries(['products', { orderProductGroup }]),
  });

  const HandleCreateProduct: SubmitHandler<Product> = async (product) => {
    try {
      await mutateCreateProduct(product);
      props.onSuccess && props.onSuccess();
      message.success('New product added');
    } catch (error) {
      console.error('Error while creating a product -> ', error);
      message.error(error);
    }
  };

  return <ProductForm onSubmit={HandleCreateProduct} {...props} />;
}
