import React, { useCallback, useState } from 'react';
import { Product } from 'models/product';
import { useQuery } from 'react-query';
import { GetProduct } from 'services/products';
import { useStoreState } from 'store';
import { Col, Empty, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { ProductOptionLimitType, ProductOptionType } from 'enums/product';
import Tags from 'components/tags';
import { CreateProductOptionItem } from '../create';

interface IProductOptionListProps {
  product: Product;
}

const ProductOptionList: React.FunctionComponent<IProductOptionListProps> = ({ product, ...props }) => {
  const { orderProductGroup } = useStoreState((store) => store.Globals);

  const [createFormVisibility, setcreateFormVisibility] = useState(false);

  const { data, isLoading, isFetching, refetch } = useQuery(['product', { productId: product.id, orderProductGroup: orderProductGroup }], GetProduct, { refetchOnMount: true, staleTime: 5000, cacheTime: 5000 });

  const toggleCreateFormVisibility = useCallback(() => setcreateFormVisibility((v) => !v), []);

  return isLoading ? (
    <div style={{ marginBottom: 16 }}>
      <Skeleton paragraph={{ rows: 4, width: 100 }} active></Skeleton>
    </div>
  ) : (
    <Row gutter={[20, 10]}>
      <Col span={24}>
        <Typography.Title level={5}>Product Options</Typography.Title>
      </Col>
      {data?.productOptions?.length ? (
        <>
          <Col span={24}>
            {data?.productOptions.map((option) => (
              <Row gutter={[0, 12]} key={option.id}>
                <Col lg={2} xs={24}>
                  {option.name}
                </Col>
                <Col lg={4}>
                  <Space>
                    {option.limitQuantity ? (
                      <span>
                        choice should {option.limitBy === ProductOptionLimitType.Equal ? 'equal' : option.limitBy === ProductOptionLimitType.Maximum ? 'maximum' : option.limitBy === ProductOptionLimitType.Minimum && 'minimum'} {option.limitQuantity}
                      </span>
                    ) : null}
                    <span>
                      <small>({option.isRequired ? 'required' : 'not required'})</small>
                    </span>
                  </Space>
                </Col>
                <Col lg={10}>
                  <Space>
                    <Space size={2}>
                      {option.productOptionItems?.length ? (
                        <Tags
                          tags={option.productOptionItems.map((pot) => (
                            <div>
                              {pot.name}
                              {pot.selected && (
                                <Typography.Text type="success" style={{ fontSize: 10, paddingLeft: 3 }}>
                                  (auto selected)
                                </Typography.Text>
                              )}
                            </div>
                          ))}
                        />
                      ) : //  option.productOptionItems.map((optionItem) => (
                      //     <Tag>
                      //       {optionItem.name}{' '}
                      //       {optionItem.selected && (
                      //         <Typography.Text type="success" style={{ fontSize: 10, paddingLeft: 3 }}>
                      //           (auto selected)
                      //         </Typography.Text>
                      //       )}
                      //     </Tag>
                      //   ))
                      null}
                    </Space>
                  </Space>
                </Col>
                <Col>
                  <div>
                    can select <b>{option.type === ProductOptionType.Multiple ? 'multiple' : option.type === ProductOptionType.MultipleQuantity ? 'multiple of each option items' : option.type === ProductOptionType.Single && 'only one of them'}</b>
                  </div>
                </Col>
              </Row>
            ))}
          </Col>
        </>
      ) : (
        <Col span={24}>
          <Typography.Text type="secondary">We couldn't find any product options, if you wish you can add some</Typography.Text>
        </Col>
      )}
      <Col span={24}>
        <Typography.Link onClick={toggleCreateFormVisibility}>Add new option</Typography.Link>
      </Col>
      <CreateProductOptionItem product={product} onSuccess={toggleCreateFormVisibility} onCancel={toggleCreateFormVisibility} title={`Add new option to ${product.name}`} visible={createFormVisibility} />
    </Row>
  );
};

export default React.memo(ProductOptionList);
