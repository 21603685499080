import { createTypedHooks } from 'easy-peasy';

import Auth from './auth';
import Globals from './globals';
import IStoreModel from './model';

const Store = {
  Auth: Auth,
  Globals: Globals,
};

export const ConfigureStore = {
  name: 'Stormia-restaurant-client',
};

export default Store;

const { useStoreActions, useStoreState, useStoreDispatch, useStore } = createTypedHooks<IStoreModel>();
export { useStoreActions, useStoreState, useStoreDispatch, useStore };
