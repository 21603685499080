import { yupResolver } from '@hookform/resolvers';
import { Col, Input, InputNumber, message, Row, Space, Tag, Typography } from 'antd';
import { IGlobalServerResponse } from 'API/api';
import { FadeIn } from 'components/animate/fade-in';
import ErrorItem from 'components/error-item';
import FormItem from 'components/form-item';
import FormModal, { IFormModal } from 'components/form-modal/form-modal';
import Select, { Option } from 'components/select';
import { AnimatePresence } from 'framer-motion';
import { Category } from 'models/category';
import { Product } from 'models/product';
import * as React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { GetCategories } from 'services/categories';
import { AddCategoryToProduct, DeleteProductCategory } from 'services/products';
import { useStoreState } from 'store';
import * as yup from 'yup';

export interface IProductFormProps extends IFormModal {
  product?: Product;
}

const ProductFormValidation = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().notRequired().nullable(),
  categories: yup.array(),
  price: yup.number().required(),
  discountedPrice: yup.number().notRequired().nullable().default(null),
  orderProductGroup: yup.number().required(),
});

export function ProductForm(props: IProductFormProps) {
  const { product } = props;

  const { orderProductGroup } = useStoreState((store) => store.Globals);

  const cache = useQueryCache();

  const { data: categories, isLoading } = useQuery(['category'], GetCategories, { enabled: !product });

  const ProductFormMethods = useForm<Product>({ defaultValues: product, resolver: yupResolver(ProductFormValidation) });

  const [mutateDeleteProductCategory] = useMutation(DeleteProductCategory, {
    onSuccess: (response: IGlobalServerResponse) => {
      message.destroy();
      message.success('Product category removed');
      return cache.invalidateQueries(['product', { productId: product?.id }]);
    },
    onSettled: () => cache.invalidateQueries(['products']),
  });

  React.useEffect(() => {
    if (product) {
      ProductFormMethods.reset(product);
    }

    return () => {};
  }, [product]);

  const { control, errors, register } = ProductFormMethods;

  return (
    <FormProvider {...ProductFormMethods}>
      <FormModal {...props}>
        <input type="hidden" value={orderProductGroup} name="orderProductGroup" defaultValue={orderProductGroup} ref={register} />
        <Row gutter={10}>
          <Col span={24}>
            <ErrorItem error={errors.name}>
              <FormItem error={errors.name}>
                <Controller as={<Input placeholder="Product Name" />} control={control} name="name" />
              </FormItem>
            </ErrorItem>
          </Col>
          <Col span={24}>
            <ErrorItem error={errors.description}>
              <FormItem error={errors.description}>
                <Controller as={<Input.TextArea rows={3} placeholder="Product description" />} control={control} name="description" />
              </FormItem>
            </ErrorItem>
          </Col>

          <Col span={12}>
            <ErrorItem error={errors.price}>
              <FormItem error={errors.price}>
                <Controller as={<InputNumber decimalSeparator="." min={0} placeholder="Price" />} control={control} name="price" />
              </FormItem>
            </ErrorItem>
          </Col>
          {/* <Col span={12}>
            <ErrorItem error={errors.discountedPrice}>
              <FormItem error={errors.discountedPrice}>
                <Controller as={<InputNumber decimalSeparator="." min={0} placeholder="Discounted Price" />} control={control} name="discountedPrice" />
              </FormItem>
            </ErrorItem>
          </Col> */}

          {product && product.id ? (
            <Col span={24}>
              <FormItem>
                <Space direction="vertical" size={8}>
                  {product.categories && product.categories.length > 0 && (
                    <div>
                      {product?.categories
                        ?.sort((a, b) => (a.name && b.name ? (a.name < b?.name ? -1 : 1) : -1))
                        .map(
                          (cat) =>
                            cat.id && (
                              <Tag key={cat.id} closable onClose={() => (product.id && cat.id ? mutateDeleteProductCategory({ productId: product.id, categoryId: cat.id }) : undefined)} color="rgb(125, 137, 185)">
                                {cat.name}
                              </Tag>
                            ),
                        )}
                    </div>
                  )}
                  <ProductAddCategory product={product} />
                </Space>
              </FormItem>
            </Col>
          ) : (
            <Col span={12}>
              <ErrorItem error={errors.categories}>
                <FormItem>
                  <Controller
                    render={(props) => (
                      <Select maxTagCount={4} onChange={props.onChange} onBlur={props.onBlur} value={props.value} mode="multiple" placeholder="Please select category" optionFilterProp="children" loading={isLoading}>
                        {categories?.map(
                          (category) =>
                            category?.id && (
                              <Option key={category.id} value={category.id}>
                                {category.name}
                              </Option>
                            ),
                        )}
                      </Select>
                    )}
                    defaultValue={[]}
                    control={control}
                    name="categories"
                  />
                </FormItem>
              </ErrorItem>
            </Col>
          )}
        </Row>
      </FormModal>
    </FormProvider>
  );
}

interface IAddCategoryProduct {
  product: Product;
}

interface IAddCategoryForm {
  categoryId: string;
}

const ProductAddCategory: React.FunctionComponent<IAddCategoryProduct> = ({ product, ...props }) => {
  // const { orderProductGroup } = useStoreState((store) => store.Globals);

  const [visible, setvisible] = React.useState(false);

  const { control, errors, formState, handleSubmit, reset, setError, watch } = useForm<IAddCategoryForm>();
  const { data: categoriesData, isLoading } = useQuery(['category'], GetCategories, { enabled: product.id ? true : false });

  const cache = useQueryCache();

  const $selectedCategory = watch('categoryId');

  const [addCategoryMutate, { isLoading: isSaving }] = useMutation(AddCategoryToProduct, {
    onMutate: async (variables) => {
      const $product: Product | undefined = cache.getQueryData(['product', { productId: product.id }]);

      const updatedProduct: Product = { ...$product };
      const cats: Category[] | undefined = cache.getQueryData(['category']);

      if (cats && updatedProduct) {
        const selectedCategory = cats.find((c) => c.id === variables.categoryId);
        if (selectedCategory && !updatedProduct.categories?.find((c) => c.id === selectedCategory.id)) {
          updatedProduct.categories?.push(selectedCategory);
          cache.setQueryData(['product', { productId: product.id }], updatedProduct);
        }
      }

      return () => cache.setQueryData(['product', { productId: product.id }], $product);
    },
    // onError: (err, variables, rollback) => rollback(),
    throwOnError: true,
  });

  const handleEditInputConfirm = async (form: IAddCategoryForm) => {
    if (!product.id) return;

    setvisible(false);

    try {
      await addCategoryMutate({ categoryId: form.categoryId, productId: product.id });
      reset();
    } catch (error) {
      setError('categoryId', error);
      message.error(error);
    }
  };

  React.useEffect(() => {
    console.log('$selectedCategory', $selectedCategory);
    if ($selectedCategory) {
      handleEditInputConfirm({ categoryId: $selectedCategory });
    }
  }, [$selectedCategory]);

  const $Categories = categoriesData?.filter((c) => !product.categories?.find((cat) => cat.id === c.id));

  return (
    <div style={{ height: 30 }}>
      <AnimatePresence initial={false} exitBeforeEnter={true}>
        {visible ? (
          <FadeIn>
            <Space>
              <ErrorItem error={errors.categoryId}>
                <Controller
                  control={control}
                  name="categoryId"
                  render={(props) => (
                    <Select
                      showSearch
                      onChange={props.onChange}
                      style={{ width: 160 }}
                      defaultOpen={true}
                      size="small"
                      disabled={isSaving}
                      onBlur={props.onBlur}
                      value={props.value}
                      placeholder="Add another category"
                      optionFilterProp="children"
                      loading={isLoading}
                    >
                      {$Categories?.map(
                        (category) =>
                          category.id && (
                            <Option key={category.id} value={category.id}>
                              {category.name}
                            </Option>
                          ),
                      )}
                    </Select>
                  )}
                />
              </ErrorItem>
              {/* <Button onClick={handleSubmit(handleEditInputConfirm)} type="primary" size="small">
                save
              </Button> */}
            </Space>
          </FadeIn>
        ) : (
          <FadeIn>
            <Typography.Link color="blue" onClick={() => setvisible(true)}>
              {product.categories && product.categories?.length > 0 ? 'add another category' : 'add category'}
            </Typography.Link>
          </FadeIn>
        )}
      </AnimatePresence>
    </div>
  );
};
