import { API_V1, API_V2 } from 'API';
import { OrderProductGroup } from 'enums/product';
import { Category } from 'models/category';
import { Product, ProductOption } from 'models/product';

export interface ProductsQueryParams {
  orderProductGroup: OrderProductGroup;
}

export interface ProductQueryParams {
  productId: string;
  orderProductGroup: OrderProductGroup;
}

export async function GetProducts(key: string, params: ProductsQueryParams) {
  return await API_V2.get<Product[]>('product/list-all', { params });
}

export async function GetProduct(key: string, params: ProductQueryParams) {
  return await API_V2.get<Product>(`product`, { params });
}

// export async function UpdateProduct(product: Product) {
//   return await API.
// }

export async function CreateProduct(product: Product) {
  console.log('Create ?', product);
  return await API_V2.put('product', product);
}

export async function UpdateProduct(product: Product) {
  console.log('Create ?', product);
  return await API_V1.post('product/update', product, { params: { productId: product.id } });
}

export async function DeleteProduct(productId: string) {
  return await API_V1.delete('product', { params: { productId } });
}

export async function AddProductOption(product: Product, options: ProductOption[]) {
  console.log('Create option item to ?', product);
  return await API_V1.put(`product/add-options`, options, { params: { productId: product.id } });
}

export async function AddCategoryToProduct(params: { productId: string; categoryId: string }) {
  return await API_V1.put('product/add-category', undefined, { params });
}

export async function DeleteProductCategory(params: { productId: string; categoryId: string }) {
  return await API_V1.delete('product/remove-category', { params });
}
