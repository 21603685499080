import { Button, Col, Divider, Input, Row } from 'antd';
import * as React from 'react';
import Icon from 'react-icons-kit';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { Route } from 'react-router-dom';
import { CreateCategoryView } from './create';
import CategoriesList from './list';
import { UpdateCategoryForm } from './update';

export interface ICategoriesPageProps {}

export default function CategoriesPage(props: ICategoriesPageProps) {
  const [searchValue, setsearchValue] = React.useState<string>('');
  const searchInput = React.useRef(null);
  const [createCategoryVisibility, setCreateCategoryVisibility] = React.useState(false);

  const toggleCreateCategoryFormVisibility = React.useCallback(() => {
    setCreateCategoryVisibility((state) => !state);
  }, []);

  return (
    <Row>
      <Col xs={24} lg={18} xl={12} xxl={10}>
        <Row gutter={[10, 10]}>
          <Col flex={'1 1 60%'}>
            <Input.Search size="large" allowClear ref={searchInput} placeholder="Type category name.." onChange={(e) => (e.currentTarget.value.length < 3 ? setsearchValue('') : setsearchValue(e.currentTarget.value.trim()))} />
          </Col>
          <Col flex="1 1 10%">
            <CreateCategoryView onSuccess={toggleCreateCategoryFormVisibility} onCancel={toggleCreateCategoryFormVisibility} visible={createCategoryVisibility} title="Create new category" />
            <Button onClick={toggleCreateCategoryFormVisibility} block type="link" icon={<Icon icon={ic_add} size={18} />} size="large">
              Add new category
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={24}>
        <CategoriesList filter={(data) => data.filter((d) => d.name?.toLocaleLowerCase().includes(searchValue) || d.description?.toLocaleLowerCase().includes(searchValue))} />
      </Col>
      <Route path="/categories/:categoryId" render={(props) => <UpdateCategoryForm {...props} />} />
    </Row>
  );
}
