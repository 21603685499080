import { yupResolver } from '@hookform/resolvers';
import { Col, Input, Row } from 'antd';
import Button from 'components/button';
import ErrorItem from 'components/error-item';
import FormItem from 'components/form-item';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useStoreActions } from 'store';
import { sleep } from 'utils';
import * as yup from 'yup';

const LoginValidation = yup.object().shape<LoginFormInputs>({
  email: yup.string().required('Kullanıcı adı boş bırakılamaz'),
  password: yup.string().required('Şifre boş bırakılamaz'),
});
interface ILoginFormProps {}

export type LoginFormInputs = {
  email: string;
  password: string;
};

const LoginForm: React.FunctionComponent<ILoginFormProps> = (props) => {
  const { handleSubmit, control, formState, errors, setError, clearErrors } = useForm<LoginFormInputs>({
    resolver: yupResolver(LoginValidation),
    shouldFocusError: true,
    reValidateMode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  });

  const { login } = useStoreActions((store) => store.Auth);

  const onSubmit: SubmitHandler<LoginFormInputs> = async (form) => {
    console.log('Loggining..', form);

    try {
      await sleep(500);
      const token = await login(form);
      console.log('Login Success', token);
    } catch (error) {
      console.error('Error login -> ', error);
      setError('email', { message: error, type: 'validate' });
      setError('password', { message: error, type: 'validate' });
    }
  };

  React.useEffect(() => {
    if (!errors && errors === {}) return;

    const clearErr = setInterval(() => {
      clearErrors();
    }, 2500);

    return () => {
      clearInterval(clearErr);
    };
  }, [errors]);

  const { isSubmitting } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[12, 20]}>
        <Col span="24">
          <ErrorItem error={errors.email?.message}>
            <FormItem error={errors.email}>
              <Controller defaultValue={null} control={control} name="email" as={<Input disabled={isSubmitting} placeholder="example@mobideli.co.uk" />} />
            </FormItem>
          </ErrorItem>
        </Col>
        <Col span="24">
          <ErrorItem error={errors.password?.message}>
            <FormItem error={errors.password}>
              <Controller defaultValue={null} control={control} name="password" as={<Input.Password disabled={isSubmitting} placeholder="********" />} />
            </FormItem>
          </ErrorItem>
        </Col>
        <Col span="24">
          <Button htmlType="submit" type="primary" loading={isSubmitting}>
            Login
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default LoginForm;
