import * as React from 'react';
import './App.css';
import Routes from './routes';
import { createStore, StoreProvider } from 'easy-peasy';
import Store, { ConfigureStore } from './store';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { Alert } from 'antd';

const store = createStore(Store, ConfigureStore);

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      staleTime: Infinity,
    },
  },
});

function App() {
  return (
    <StoreProvider store={store}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <Alert.ErrorBoundary>
          <Routes />
        </Alert.ErrorBoundary>
      </ReactQueryCacheProvider>
    </StoreProvider>
  );
}

export default App;
