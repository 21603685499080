import { action, Action, persist } from 'easy-peasy';
import { OrderProductGroup } from 'enums/product';

interface IGlobalsStoreState {
  orderProductGroup: OrderProductGroup;
}

export interface IGlobalsStoreModel extends IGlobalsStoreState {
  setOrderProductGroup: Action<IGlobalsStoreState, OrderProductGroup>;
}

const Globals: IGlobalsStoreModel = persist(
  {
    orderProductGroup: OrderProductGroup.DeliveryTakeaway,
    setOrderProductGroup: action((state, payload) => {
      state.orderProductGroup = payload;
    }),
  },
  { blacklist: ['orderProductGroup'], storage: 'localStorage' },
);

export default Globals;
