import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './form';
import './style.less';

const LoginRoute: React.FunctionComponent = () => {
  return (
    <div className="login-form-wrapper">
      <Row>
        <Col span="24">
          <Typography.Title level={4}>Login</Typography.Title>
        </Col>
        <Col>
          <LoginForm />
        </Col>
      </Row>
    </div>
  );
};

export default LoginRoute;
