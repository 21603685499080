export enum OrderProductGroup {
  None = 0,
  DeliveryTakeaway = 1,
  EatIn = 2,
}

// Product Option Selection Type
export enum ProductOptionType {
  None = 0,
  Single = 1, // You can select only one of option items
  Multiple = 2, // You can select multiple of option items
  MultipleQuantity = 3, // You can select multiple of each option items
}

// This only works with “Multiple” and “MultipleQuantity” selection of “ProductOptionType”
export enum ProductOptionLimitType {
  None = 0,
  Minimum = 1, // You can select at least “X” quantity of the option items
  Equal = 2, // You can select predefined exact quantity of the option items
  Maximum = 3, // You can select maximum “X” quantity of the option item
}

export enum ProductStatus {
  None = 0,
  Published,
  Unpublished,
}
