import React from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps } from 'antd/lib/input';

export interface IInputProps extends InputProps {}

const Input = React.forwardRef((props: IInputProps) => {
  return <AntdInput {...props} />;
});
export default Input;
