import { Button, Checkbox, Col, Divider, Row, Typography } from 'antd';
import FormModal, { IFormModal } from 'components/form-modal/form-modal';
import Input from 'components/input/input';
import Select from 'components/select';
import { Product, ProductOption, ProductOptionItem } from 'models/product';
import { ProductOptionType, ProductOptionLimitType } from 'enums/product';
import * as React from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import FormItem from 'components/form-item';
import Icon from 'react-icons-kit';
import { closeRound } from 'react-icons-kit/ionicons/closeRound';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ErrorItem from 'components/error-item';

const ProductOptionTypes = [
  {
    label: 'Can select only one of option',
    value: ProductOptionType.Single,
  },
  {
    label: 'Can select multiple of option items',
    value: ProductOptionType.Multiple,
  },
  {
    label: 'Can select multiple of each option items',
    value: ProductOptionType.MultipleQuantity,
  },
];

const ProductOptionLimitTypes = [
  {
    label: 'should minimum',
    value: ProductOptionLimitType.Minimum,
  },
  {
    label: 'should equal ',
    value: ProductOptionLimitType.Equal,
  },
  {
    label: 'should maximum',
    value: ProductOptionLimitType.Maximum,
  },
];

const ProductOptionValidation = yup.object().shape({
  name: yup.string().required(),
  type: yup.number().oneOf([ProductOptionType.Multiple, ProductOptionType.MultipleQuantity, ProductOptionType.Single]),
  limitBy: yup
    .number()
    .when('type', { is: (val) => val === ProductOptionType.Multiple || val === ProductOptionType.MultipleQuantity, then: yup.number().oneOf([ProductOptionLimitType.Minimum, ProductOptionLimitType.Maximum, ProductOptionLimitType.Equal]).required() }),
  limitQuantity: yup.number().when('type', { is: (val) => val === ProductOptionType.Multiple || val === ProductOptionType.MultipleQuantity, then: yup.number().required() }),
  isRequired: yup.boolean().notRequired(),
  productOptionItems: yup
    .array(
      yup.object().shape({
        name: yup.string().required(),
        price: yup.number().min(0).required(),
        quantity: yup.number().min(0).required(),
        selected: yup.boolean().notRequired(),
      }),
    )
    .min(1)
    .required(),
});

export interface IOptionItemFormProps extends IFormModal {
  option?: ProductOptionItem;
}

const ProductOptionForm: React.FunctionComponent<IOptionItemFormProps> = ({ option, ...props }) => {
  const formMethods = useForm<ProductOption>({ defaultValues: option, resolver: yupResolver(ProductOptionValidation) });

  const { control, errors, watch } = formMethods;

  const type = watch('type');

  return (
    <FormProvider {...formMethods}>
      <FormModal width={680} {...props}>
        <Row gutter={12}>
          <Col span={24}>
            <ErrorItem error={errors.name}>
              <FormItem error={errors.name}>
                <Controller control={control} name="name" as={<Input placeholder="Option name (Ex: 'Sides')" />} />
              </FormItem>
            </ErrorItem>
          </Col>
          <Col span={24}>
            <ErrorItem error={errors.type}>
              <FormItem error={errors.type}>
                <Controller defaultValue={null} control={control} name="type" as={<Select placeholder="What kind of a option" options={ProductOptionTypes} />} />
              </FormItem>
            </ErrorItem>
          </Col>
          {type === ProductOptionType.Multiple || type === ProductOptionType.MultipleQuantity ? (
            <>
              <Col span={12}>
                <ErrorItem error={errors.limitBy}>
                  <FormItem error={errors.limitBy}>
                    <Controller defaultValue={null} control={control} name="limitBy" as={<Select placeholder="Limit option type" options={ProductOptionLimitTypes} />} />
                  </FormItem>
                </ErrorItem>
              </Col>
              <Col span={12}>
                <ErrorItem error={errors.limitQuantity}>
                  <FormItem error={errors.limitQuantity}>
                    <Controller defaultValue={1} control={control} name="limitQuantity" as={<Input type="number" min={1} />} />
                  </FormItem>
                </ErrorItem>
              </Col>
            </>
          ) : null}
          <Col span={24}>
            <FormItem marginBottom={0}>
              <Controller
                control={control}
                defaultValue={false}
                name="isRequired"
                render={({ onChange, value, ...props }) => (
                  <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} {...props}>
                    set as required option
                  </Checkbox>
                )}
              />
            </FormItem>
          </Col>
        </Row>
        <Divider />
        <div style={{ height: 10 }} />
        <OptionItems />
      </FormModal>
    </FormProvider>
  );
};

export default ProductOptionForm;

interface IOptionItems {}

const OptionItems: React.FunctionComponent<IOptionItems> = (props) => {
  const { control, errors, clearErrors } = useFormContext<ProductOption>();

  const { append, prepend, remove, fields } = useFieldArray<ProductOptionItem>({ name: 'productOptionItems', control: control });

  React.useEffect(() => {
    append({ name: undefined, price: undefined, quantity: undefined, selected: undefined, displayOrder: undefined });
  }, []);

  return (
    <>
      {fields.map((field, index) => (
        <Row gutter={12} key={field.id} align="middle" justify="space-between">
          <Col span={24}>
            <Typography.Text type="secondary">item {index + 1}</Typography.Text>
          </Col>
          <Col flex="1 0 25%">
            <ErrorItem error={errors.productOptionItems && errors.productOptionItems[0]?.name}>
              <FormItem error={errors.productOptionItems && errors.productOptionItems[0]?.name}>
                <Controller name={`productOptionItems[${index}]name`} control={control} as={<Input placeholder="Option item name" />} />
              </FormItem>
            </ErrorItem>
          </Col>
          <Col flex="1 0 10%">
            <ErrorItem error={errors.productOptionItems && errors.productOptionItems[0]?.name}>
              <FormItem error={errors.productOptionItems && errors.productOptionItems[0]?.name}>
                <Controller name={`productOptionItems[${index}]price`} control={control} as={<Input min={0} type="number" placeholder="Price" />} />
              </FormItem>
            </ErrorItem>
          </Col>
          <Col flex="1 0 10%">
            <ErrorItem error={errors.productOptionItems && errors.productOptionItems[0]?.name}>
              <FormItem error={errors.productOptionItems && errors.productOptionItems[0]?.name}>
                <Controller name={`productOptionItems[${index}]quantity`} control={control} as={<Input min={0} type="number" placeholder="Quantity" />} />
              </FormItem>
            </ErrorItem>
          </Col>
          <Col flex="1 0 15%">
            <FormItem>
              <Controller
                name={`productOptionItems[${index}]selected`}
                defaultValue={false}
                control={control}
                render={({ onChange, onBlur, name, value }) => (
                  <Checkbox checked={value} name={name} onChange={(e) => onChange(e.target.checked)}>
                    default selected
                  </Checkbox>
                )}
              />
            </FormItem>
          </Col>
          <Col flex="0 0 30px">
            {index > 0 && (
              <FormItem>
                <Typography.Link type="danger" onClick={() => remove(index)}>
                  <Icon icon={closeRound} size={14} />
                </Typography.Link>
              </FormItem>
            )}
          </Col>
        </Row>
      ))}
      <Button
        onClick={() => {
          clearErrors();
          append({});
        }}
        size="small"
        type="dashed"
      >
        Add +1
      </Button>
    </>
  );
};
