import { Avatar, Button, Card, Col, Input, InputNumber, List, Row, Space, Typography } from 'antd';
import * as React from 'react';
import { useQuery } from 'react-query';
import { GetCategoriesWithProduct } from 'services/categories';

export interface IMenuPageProps {}

export default function MenuPage(props: IMenuPageProps) {
  const { data, isLoading } = useQuery('categories-extend', GetCategoriesWithProduct);

  return (
    <Card style={{ maxWidth: 1280 }}>
      {isLoading ? (
        <div>Yükleniyor..</div>
      ) : (
        <div>
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                extra={
                  <Space>
                    <Button type="link">Düzenle</Button>
                  </Space>
                }
                title={item.name}
              >
                <Row style={{ width: '100%' }}>
                  <Col span={24}>
                    <List.Item.Meta title={item.name} description={item.description} avatar={<Avatar size={46} src={item.imageUrl} />}></List.Item.Meta>
                  </Col>
                  <Col span={24}>
                    {item.products &&
                      item.products.length &&
                      item.products.map((product) => (
                        <Row gutter={12} style={{ paddingLeft: 60, marginTop: 20 }}>
                          <Col flex="1 0 auto">
                            <Typography.Text>{product.name}</Typography.Text>
                          </Col>
                          <Col flex="0 0 100px">{product.price}</Col>
                          <Col span={24}>
                            <Typography.Paragraph>{product.description}</Typography.Paragraph>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </div>
      )}
    </Card>
  );
}
